import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers: (state, { payload }) => {
      return payload;
    },
  },
});

const previewSlice = createSlice({
  name: 'preview',
  initialState: false,
  reducers: {
    setPreview: (state, { payload }) => {
      if (payload) {
        localStorage.setItem('preview', payload);
      } else {
        localStorage.removeItem('preview');
      }
      return payload;
    },
  },
});

const totalUsersSlice = createSlice({
  name: 'totalUsers',
  initialState: 0,
  reducers: {
    setTotalUsers: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;
export const { setTotalUsers } = totalUsersSlice.actions;
export const { setPreview } = previewSlice.actions;

export default {
  users: usersSlice.reducer,
  totalUsers: totalUsersSlice.reducer,
  preview: previewSlice.reducer,
};
