import { createSlice } from '@reduxjs/toolkit';

import { formatDate } from 'src/utils/get-time';

const plannersDaySlice = createSlice({
  name: 'plannersDay',
  initialState: [],
  reducers: {
    setPlannersDay: (state, { payload }) => {
      return payload;
    },
  },
});

const plannersEveningSlice = createSlice({
  name: 'plannersEvening',
  initialState: [],
  reducers: {
    setPlannersEvening: (state, { payload }) => {
      return payload;
    },
  },
});

const plannersNightSlice = createSlice({
  name: 'plannersNight',
  initialState: [],
  reducers: {
    setPlannersNight: (state, { payload }) => {
      return payload;
    },
  },
});

const plannersBakerySlice = createSlice({
  name: 'plannersBakery',
  initialState: [],
  reducers: {
    setPlannersBakery: (state, { payload }) => {
      return payload;
    },
  },
});

const plannersPackagingSlice = createSlice({
  name: 'plannersPackaging',
  initialState: [],
  reducers: {
    setPlannersPackaging: (state, { payload }) => {
      return payload;
    },
  },
});

const startDateSlice = createSlice({
  name: 'startDate',
  initialState: formatDate(new Date()),
  reducers: {
    setStartDate: (state, { payload }) => {
      return payload;
    },
  },
});

const endDateSlice = createSlice({
  name: 'endDate',
  initialState: formatDate(new Date()),
  reducers: {
    setEndDate: (state, { payload }) => {
      return payload;
    },
  },
});

const datesSlice = createSlice({
  name: 'dates',
  initialState: [],
  reducers: {
    setDates: (state, { payload }) => {
      return payload;
    },
  },
});

const currentDateSlice = createSlice({
  name: 'currentDate',
  initialState: formatDate(new Date()),
  reducers: {
    setCurrentDate: (state, { payload }) => {
      return payload;
    },
  },
});

const overTimeSlice = createSlice({
  name: 'overtimes',
  initialState: [],
  reducers: {
    setOverTime: (state, { payload }) => {
      return payload;
    },
  },
});

const overTimeBakerySlice = createSlice({
  name: 'overtimesBakery',
  initialState: [],
  reducers: {
    setOverTimeBakery: (state, { payload }) => {
      return payload;
    },
  },
});

const overTimePackagingSlice = createSlice({
  name: 'overTimesPackaging',
  initialState: [],
  reducers: {
    setOverTimePackaging: (state, { payload }) => {
      return payload;
    },
  },
});

const typeSlice = createSlice({
  name: 'type',
  initialState: 'day',
  reducers: {
    setType: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setPlannersDay } = plannersDaySlice.actions;
export const { setPlannersEvening } = plannersEveningSlice.actions;
export const { setPlannersNight } = plannersNightSlice.actions;
export const { setStartDate } = startDateSlice.actions;
export const { setEndDate } = endDateSlice.actions;
export const { setDates } = datesSlice.actions;
export const { setCurrentDate } = currentDateSlice.actions;
export const { setOverTime } = overTimeSlice.actions;
export const { setType } = typeSlice.actions;
export const { setPlannersBakery } = plannersBakerySlice.actions;
export const { setPlannersPackaging } = plannersPackagingSlice.actions;
export const { setOverTimeBakery } = overTimeBakerySlice.actions;
export const { setOverTimePackaging } = overTimePackagingSlice.actions;
export default {
  plannersDay: plannersDaySlice.reducer,
  plannersEvening: plannersEveningSlice.reducer,
  plannersNight: plannersNightSlice.reducer,
  plannersBakery: plannersBakerySlice.reducer,
  plannersPackaging: plannersPackagingSlice.reducer,
  startDate: startDateSlice.reducer,
  endDate: endDateSlice.reducer,
  dates: datesSlice.reducer,
  currentDate: currentDateSlice.reducer,
  overtimes: overTimeSlice.reducer,
  overtimesBakery: overTimeBakerySlice.reducer,
  overtimesPackaging: overTimePackagingSlice.reducer,
  type: typeSlice.reducer,
};
