import gql from 'graphql-tag';

export const ME = gql`
  query ME {
    me {
      id
      email
      name
      kompetens
      omrade
      utb
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS {
    users {
      id
      email
      name
      phone
      position
      kompetens
      omrade
      utb
      TotalPointAndSalary {
        salary
        year
        point
      }
    }
    totalUsers
  }
`;

export const TOTAL_USERS = gql`
  query TOTAL_USERS {
    totalUsers
  }
`;
