import { createSlice } from '@reduxjs/toolkit';

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: [],
  reducers: {
    setAnnouncements: (state, { payload }) => {
      return payload;
    },
  },
});


const totalAnnouncementstsSlice = createSlice({
  name: 'totalAnnouncements',
  initialState: 0,
  reducers: {
    setTotalAnnouncements: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setAnnouncements } = announcementsSlice.actions;
export const { setTotalAnnouncements } = totalAnnouncementstsSlice.actions;

export default {
  announcements: announcementsSlice.reducer,
  totalAnnouncements: totalAnnouncementstsSlice.reducer,
};
