import { createSlice } from '@reduxjs/toolkit';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: [],
  reducers: {
    setComments: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setComments } = commentsSlice.actions;

export default {
  comments: commentsSlice.reducer,
};
