import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import users from './users';
import posts from './posts';
import comments from './comments';
import workschedule from './workschedule';
import announcements from './announcements';
import group from './group';
import planners from './planners';
import qualities from './quality';
import operators from './operators';

export default combineReducers({
  ...auth,
  ...users,
  ...posts,
  ...group,
  ...comments,
  ...workschedule,
  ...announcements,
  ...planners,
  ...qualities,
  ...operators,
});
