import { createSlice } from '@reduxjs/toolkit';

const groupsSlice = createSlice({
  name: 'groups',
  initialState: [],
  reducers: {
    setGroups: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setGroups } = groupsSlice.actions;

export default {
  groups: groupsSlice.reducer,
}
