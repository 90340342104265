import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from 'src/utils/get-time';

const operatorsSlice = createSlice({
  name: 'operators',
  initialState: [],
  reducers: {
    setOperators: (state, { payload }) => {
      return payload;
    },
  },
});

const datesSlice = createSlice({
  name: 'monthDates',
  initialState: [],
  reducers: {
    setMonthDates: (state, { payload }) => {
      return payload;
    },
  },
});

const currentDateSlice = createSlice({
  name: 'currentMonthDate',
  initialState: formatDate(new Date()),
  reducers: {
    setCurrentMonthDate: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setOperators } = operatorsSlice.actions;
export const { setMonthDates } = datesSlice.actions;
export const { setCurrentMonthDate } = currentDateSlice.actions;

export default {
  operators: operatorsSlice.reducer,
  monthDates: datesSlice.reducer,
  currentMonthDate: currentDateSlice.reducer,
};
