module.exports = {
  /* SideBar and header */
  "Log in": "Log in",
  "Statistics": "Statistics",
  "Posts": "Posts",
  "Post List": "Post List",
  "Work Schedule": "Work Schedule",
  "Users": "Users",
  "Create Group": "Create Group",
  "Group List": "Group List",
  "Settings": "Settings",
  "Logout": "Logout",
  /* SideBar and header */

  /* Statistics Page */
  "Produced goods this year": "Produced goods this year",
  "Amount of employees": "Amount of employees",
  "Productivity": "Productivity",
  "Production Numbers": "Production Numbers",
  "Posters of this year": "Posters of this year",
  "Amount of problems with equipment": "Amount of problems with equipment",
  "TOP AUTHOR": "TOP AUTHOR",
  "Production goals achieved": "Production goals achieved",
  /* Statistics Page */ 

  /* Posts Page */
  "Write Post": "Write Post",
  "Write Admin Post": "Write Admin Post",
  "Create Admin Post": "Create Admin Post",
  "Create Post": "Create Post",
  "Post": "Post",
  "Workorder": "Workorder",
  "Place": "Place",
  "Publish Now": "Publish Now",
  "Submit": "Submit",
  "Body": "Body",
  "Latest Posts from Admin": "Latest Posts from Admin",
  "Latest Posts": "Latest Posts",
  "Date": "Date",
  "Title": "Title",
  "Author": "Author",
  "Description": "Description",
  "Action": "Action",
  "Read More": "Read More",
  "Ok": "Ok",
  "replies": "replies",
  "Add Comment": "Add Comment",
  "All Admin Posts": "All Admin Posts",
  "All Posts": "All Posts",
  "Edit Post": "Edit Post",
  "Published": "Published",
  "Unpublished": "Unpublished",
  "Cancel": "Cancel",
  /* Posts Page */

  /* Workschedule Page */
  "Create": "Create",
  "Schedule": "Schedule",
  "Time": "Time",
  "Mechanics": "Mechanics",
  "Workers": "Workers",
  "Start Time": "Start Time",
  "End Time": "End Time",
  "No one is assigned to this task": "No one is assigned to this task",
  "Assign": "Assign",
  "Update WorkSchedule": "Update WorkSchedule",
  "Assign Users": "Assign Users",
  "Mechanics Groups": "Mechanics Groups",
  "Workers Groups": "Workers Groups",
  "Age": "Age",
  "Phone": "Telefon",
  "Name": "Name",
  "Update Profile": "Update Profile",
  "Profile": "Profile",
  "Position": "Position",
  "Delete": "Delete",
  "Update": "Update",
  "Search": "Search",
  "Reset": "Reset",
  /* Workschedule Page */

  /* Users Page */
  "Create User": "Create User",
  "All Users": "All Users",
  "Edit User": "Edit User",
  "Password": "Password",
  "Confirm": "Confirm",
  "Confirm Password": "Confirm Password",
  /* Users Page */
  
  /* Group List Page */
  "All Premade Groups": "All Premade Groups",
  /* Group List Page */
};