import { createSlice } from '@reduxjs/toolkit';

const qualitySlice = createSlice({
  name: 'qualities',
  initialState: [],
  reducers: {
    setQualities: (state, { payload }) => {
      return payload;
    },
  },
});

const resolvedQualitySlice = createSlice({
  name: 'resolvedQuality',
  initialState: [],
  reducers: {
    setResolvedQualities: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setQualities } = qualitySlice.actions;
export const { setResolvedQualities } = resolvedQualitySlice.actions;
export default {
  qualities: qualitySlice.reducer,
  resolvedQuality: resolvedQualitySlice.reducer,
};
