import { createSlice } from '@reduxjs/toolkit';

const postsSlice = createSlice({
  name: 'posts',
  initialState: [],
  reducers: {
    setPosts: (state, { payload }) => {
      return payload;
    },
  },
});

const myPostsSlice = createSlice({
  name: 'myPosts',
  initialState: [],
  reducers: {
    setMyPosts: (state, { payload }) => {
      return payload;
    },
  },
});

const totalPostsSlice = createSlice({
  name: 'totalPosts',
  initialState: 0,
  reducers: {
    setTotalPosts: (state, { payload }) => {
      return payload;
    },
    setMyTotalPosts: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setPosts } = postsSlice.actions;
export const { setMyPosts } = myPostsSlice.actions;
export const { setTotalPosts, setMyTotalPosts } = totalPostsSlice.actions;

export default {
  posts: postsSlice.reducer,
  myPosts: myPostsSlice.reducer,
  totalPosts: totalPostsSlice.reducer,
};
