import { createSlice } from '@reduxjs/toolkit';

import { formatDate } from 'src/utils/get-time';

const lineupsSlice = createSlice({
  name: 'lineups',
  initialState: [],
  reducers: {
    setLineups: (state, { payload }) => {
      return payload;
    },
  },
});

const dateSlice = createSlice({
  name: 'activeDate',
  initialState: formatDate(new Date()),
  reducers: {
    setDate: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setLineups } = lineupsSlice.actions;
export const { setDate } = dateSlice.actions;
export default {
  lineups: lineupsSlice.reducer,
  activeDate: dateSlice.reducer,
};
